import React, { createContext, useState, ReactNode } from 'react';
import { User } from '../model/user.model.ts';
import { addUser, verifyUser, getCurrentUser } from '../service/user.service.ts';

interface UserContextProps {
  user: User | null;
  error: string | null;
  loging: boolean;
  fetchUser: () => Promise<void>;
  login: (user: User) => Promise<void>;
  register: (user: User, code?: string) => Promise<void>;
  logout: () => void;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loging, setLoging] = useState<boolean>(false);

  const fetchUser = async () => {
    setError(null);
    setLoging(true);
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Unable to fetch user.');
      setUser(null);
    } finally {
      setLoging(false);
    }
  };

  const login = async (userData: User) => {
    setError(null);
    setLoging(true);
    try {
      await verifyUser(userData);
    } catch (error) {
      console.error('Error during login:', error);
      setError('Login failed. Please check your credentials.');
      throw error;
    } finally {
      setLoging(false);
    }
  };

  const register = async (userData: User, code?: string) => {
    setError(null);
    setLoging(true);
    try {
      await addUser(userData, code);
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Registration failed. Please try again.');
      throw error;
    } finally {
      setLoging(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setError(null);
  };

  return (
    <UserContext.Provider
      value={{ user, error, loging, fetchUser, login, register, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};


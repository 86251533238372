import { apiAuthClient } from '../environment/env.ts';
import { Pack } from '../model/pack.model.ts';

export class PackService {
  // Récupérer tous les packs
  async getPacks(): Promise<Pack[]> {
    const response = await apiAuthClient.get<Pack[]>('/packs');
    return response.data;
  }

  // Récupérer un pack par ID
  async getPack(id: number): Promise<Pack> {
    const response = await apiAuthClient.get<Pack>(`/pack/${id}`);
    return response.data;
  }

  // Initialiser un pack
  async initializePack(id: number): Promise<void> {
    await apiAuthClient.get(`/pack/initialize/${id}`);
  }

  async getSummary(): Promise<void> {
    await apiAuthClient.get(`/pack/summary`);
  }

  // Créer un nouveau pack avec image
  async createPack(pack: Partial<Pack>, imageFile?: File): Promise<Pack> {
    const formData = new FormData();
    formData.append('pack', JSON.stringify(pack));
    if (imageFile) {
      formData.append('image', imageFile);
    }

    const response = await apiAuthClient.post<Pack>('/pack', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }

  // Mettre à jour un pack
  async updatePack(id: number, pack: Partial<Pack>): Promise<Pack> {
    const response = await apiAuthClient.put<Pack>(`/pack/${id}`, pack);
    return response.data;
  }

  // Supprimer un pack
  async deletePack(id: number): Promise<void> {
    await apiAuthClient.delete(`/pack/${id}`);
  }
}

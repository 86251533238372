import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface MultilineTextFieldsProps {
  id: string;
  label: string;
}

const MultilineTextFields = ({ id, label }: MultilineTextFieldsProps): React.JSX.Element => {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '50%' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id={id}
          label={label}
          multiline
          rows={4}
          placeholder={`<Nom d'un des ${label}> : <Coût en €>`}
          variant="filled"
        />
      </div>
    </Box>
  );
};

export default MultilineTextFields;

import React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

interface IconLabelButtonsProps {
  onClick: () => void;
}

const IconLabelButtons = ({ onClick }: IconLabelButtonsProps): React.JSX.Element => {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        onClick={onClick}
        variant="contained"
        sx={{ backgroundColor: 'brown' }}
        endIcon={<SendIcon />}
      >
        Send Pack
      </Button>
    </Stack>
  );
};

export default IconLabelButtons;

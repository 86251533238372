import { useContext } from 'react';
import { GapContext } from '../context/gap.context.tsx';

export const useGap = () => {
  const context = useContext(GapContext);
  if (!context) {
    throw new Error('useGap must be used within a GapProvider');
  }
  return context;
};

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PianoGallery from './piano/PianoGallery';
import ExpenseApp from './expense/ExpenseApp.tsx'
import ExpensePack from './expense/ExpensePack.tsx';

import PackCreate from './expense/pack/pack_create.tsx';
import SignIn from './auth/SignIn.tsx';
import { UserProvider } from '../context/user.context.tsx';
import { paths } from '../environment/path.ts';
import PackChoose from './expense/pack/pack_choose.tsx';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={
          <UserProvider>
            <SignIn />
          </UserProvider>
          } />

        <Route path={paths.SignIn} element={
          <UserProvider>
            <SignIn />
          </UserProvider>
          } />
        
        <Route path={paths.ExpenseApp} element={
          <UserProvider>
            <ExpenseApp />
          </UserProvider>
          } />
        {/* <Route path={paths.ExpensePack} element={<ExpensePack />} /> */}
        <Route path={paths.PackCreate} element={<PackCreate/>} />
        <Route path={paths.PackChoose} element={<PackChoose/>} />
        <Route path={paths.PianoGallery} element={<PianoGallery />} />
      </Routes>
    </Router>
  );
};

export default App;

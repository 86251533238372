import React from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

interface MoneyOnIconProps {
  id: string;
  updateDebit: (id: string) => void;
}

function MoneyOnIcon({ id, updateDebit }: MoneyOnIconProps): React.JSX.Element {
  const handleClick = (): void => {
    updateDebit(id);
  };

  return (
    <div>
      <MonetizationOnIcon id={id} onClick={handleClick} />
    </div>
  );
}

export default MoneyOnIcon;

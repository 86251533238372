import { useContext } from 'react';
import { DebitsContext } from '../context/debit.context.tsx';

export const useDebits = () => {
  const context = useContext(DebitsContext);
  if (!context) {
    throw new Error('useDebits must be used within a DebitsProvider');
  }
  return context;
};

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number): string {
  return `${value}€`;
}

interface DiscreteSliderMarksProps {
  defaultValue: number;
  maxValue: number;
}

function DiscreteSliderMarks({
  defaultValue,
  maxValue,
}: DiscreteSliderMarksProps): React.JSX.Element {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);

  const handleSliderChange = (_event: Event, _newValue: number | number[]): void => {
    // Empêcher le changement de valeur
    setSliderValue(defaultValue);
  };

  const numMarks = 4; // Nombre fixe de marques que vous souhaitez afficher
  const interval = Math.ceil(maxValue / (numMarks - 1));
  const marks = Array.from({ length: numMarks }, (_, i) => i * interval).map(value => ({
    value,
    label: `${value}€`,
  }));

  return (
    <Box sx={{ width: 200 }}>
      <Slider
        aria-label="Custom marks"
        value={sliderValue}
        onChange={handleSliderChange}
        getAriaValueText={valuetext}
        step={interval}
        valueLabelDisplay="auto"
        marks={marks}
        max={maxValue}
        color={(sliderValue < maxValue) ? 'primary' : 'error'}
      />
    </Box>
  );
}

export default DiscreteSliderMarks;

import React, { createContext, useState, useCallback} from 'react';
import { GapService } from '../service/gap.service.ts';

type GapContextType = {
    assumedBankAccount : number | null,
    error : string | null,
    loading : boolean,
    setAssumedBankAccount : React.Dispatch<React.SetStateAction<number | null>>,
    fetchAssumedBankAccount : (isMoneySaved : boolean) => Promise<void>
};

export const GapContext = createContext<GapContextType | null>(null);

export const GapProvider = ({ children }: { children: React.ReactNode }) => {
  const [assumedBankAccount, setAssumedBankAccount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const Gapervice = new GapService();

  const fetchAssumedBankAccount = useCallback(async (isMoneySaved : boolean) => {
    setLoading(true);
    try {
      const data = await Gapervice.getAssumedGap(isMoneySaved);
      setAssumedBankAccount(data);
    } catch {
      setError('Erreur lors de la récupération du solde bancaire estimé.');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <GapContext.Provider value={{assumedBankAccount,setAssumedBankAccount,error,loading,fetchAssumedBankAccount}}>
      {children}
    </GapContext.Provider>
  );
};

import { apiAuthClient } from '../environment/env.ts';
import { Debit } from '../model/debit.model.ts';

export class DebitService {
    
  async getDebits(): Promise<Debit[]> {
    const response = await apiAuthClient.get<Debit[]>('/debits');
    return response.data;
  }

  async getDebit(id: number): Promise<Debit> {
    const response = await apiAuthClient.get<Debit>(`/debit/${id}`);
    return response.data;
  }

  async createDebit(debit: Debit): Promise<Debit> {
    console.log(debit);
    const response = await apiAuthClient.post<Debit>('/debit', debit);
    console.log(response);
    return response.data;
  }

  async updateDebit(id: number, debit: Partial<Debit>): Promise<Debit> {
    const response = await apiAuthClient.put<Debit>(`/debit/${id}`, debit);
    return response.data;
  }

  async deleteDebit(id: number): Promise<void> {
    await apiAuthClient.delete(`/debit/${id}`);
  }
}

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface DataObject {
  id: string;
  name: string;
}

interface SelectAutoWidthProps {
  data: any[];
}

const SelectAutoWidth = forwardRef(({
  data,
}: SelectAutoWidthProps, ref): React.JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  // Expose the selected value through the ref
  useImperativeHandle(ref, () => ({
    getSelectedValue: () => selectedValue,
  }));

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="create_select"
          value={selectedValue}
          onChange={handleChange}
          autoWidth
          label="Category"
        >
          {data.map((dataObject) => (
            <MenuItem
              value={dataObject.id}
              key={dataObject.id}
            >
              {dataObject.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
});

export default SelectAutoWidth;

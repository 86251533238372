import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Typography, Checkbox, Box } from '@mui/material';
import PriceInput from './priceInput.tsx';
import { useGap } from '../../hooks/gap.hook.ts';

interface ModalInputProps {
  buttonColor: string;
  buttonTitle: string;
  descriptionHeader: string;
  callBackOnSubmit?: (bankAmount: string, movedSavings: boolean) => void;
  callBackOnClick?: () => void;
}

const GapModalInput: React.FC<ModalInputProps> = ({
  buttonColor,
  buttonTitle,
  descriptionHeader,
  callBackOnSubmit,
  callBackOnClick,
}) => {
  const [show, setShow] = useState(false);
  const [bankAmount, setBankAmount] = useState('');
  const [movedSavings, setMovedSavings] = useState(false);
  const [shouldHide, setShouldHide] = useState(true);
  const {assumedBankAccount,setAssumedBankAccount,error,fetchAssumedBankAccount} = useGap();


  const handleClose = (): void => {
    setAssumedBankAccount(null);
    setShow(false);
  };

  const handleSubmit = useCallback(async () => {
    await fetchAssumedBankAccount(movedSavings);
    setShouldHide(false);
    // setShow(false);
  },[movedSavings]);

  const handleShow = (): void => {
    if (callBackOnClick) callBackOnClick();
    setShow(true);
  };

  const gap : number | null =  assumedBankAccount ? +bankAmount - assumedBankAccount : null; 
  
  return (
    <>
      <Button size="sm" variant={buttonColor} onClick={handleShow}>
        {buttonTitle}
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          style={{
            background: 'linear-gradient(145deg, #3e2723, #6a4f37)', // Dégradé marron
            color: '#ffffff',
          }}
        >
          <Modal.Title>{descriptionHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: 'linear-gradient(145deg, #3e2723, #6a4f37)', // Dégradé marron
            color: '#ffffff',
          }}
        >
        
          <Typography variant="body1" gutterBottom>
            How much do you have in your bank account?{' '}
            <PriceInput onChange={(event) => {setBankAmount(event.target.value); setShouldHide(true);}} />
          </Typography>
          <Typography variant="body1" gutterBottom>
            Did you already move your savings to your savings account?{' '}
            <Checkbox
              color="secondary"
              checked={movedSavings}
              onChange={(e) => {setMovedSavings(e.target.checked); setShouldHide(true);}}
            />
          </Typography>

          {(!shouldHide) ? <div>
            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                gap: 1, 
              }}
            >
              <Typography variant="body1">Gap is</Typography>
              <Typography variant="body1" sx={{color : gap! >= 0 ? 'green' : 'red'}}>{gap}</Typography>
          </Box>
          <Typography variant="subtitle1">Congratulation ! you filled great the App</Typography>
          </div> : ''}

        </Modal.Body>
        <Modal.Footer
          style={{
            background: 'linear-gradient(145deg, #3e2723, #6a4f37)', // Dégradé marron
            color: '#ffffff',
          }}
        >
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit}>
            Calculate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GapModalInput;

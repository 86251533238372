import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useTheme, useMediaQuery, Typography, Box } from '@mui/material';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center' | 'inherit' | 'justify';
  format?: (value: any) => React.ReactNode;
}

interface Row {
  id: string | number;
  [key: string]: any;
}

interface ListProps {
  columns: Column[];
  rows: Row[];
  title:string;
  total ?: React.JSX.Element[]
}

const List = ({ columns, rows, title, total }: ListProps): React.JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: '100%',
        maxWidth: isSmallScreen ? '100%' : 900,
        overflow: 'hidden',
        background: 'linear-gradient(145deg, #3e2723, #6a4f37)', // Dégradé marron bois
        color: '#ffffff',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: 3,
        padding: isSmallScreen ? 1 : 3,
      }}
    >
      {/* Titre ajouté ici */}
      <Box sx={{ marginBottom: 2, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      </Box>

      <TableContainer sx={{ maxHeight: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={`tableRow${row.id}`}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={`TableCell${row.id}${column.id}`}
                        align={column.align}
                        sx={{
                          color: '#ffffff',
                          borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                        }}
                      >
                        {column.format ? column.format(row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Totaux ajoutés ici */}
      {
        total ? (<Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px',
          background: '#6a4f37',
          borderTop: '1px solid rgba(255, 255, 255, 0.2)',
        }}
      >
        {total}
      </Box>) : ''
      }
      
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          color: '#ffffff',
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-input': {
            color: '#ffffff',
          },
        }}
      />
    </Paper>
  );
};

export default List;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeBar from '../utils/homebar.tsx';
import ButtonEnvironment from '../utils/buttonEnvironment.tsx';
import Expense from './expense/expense.tsx';
import Expectation from './expectation/expectation.tsx';
import Savings from './savings/savings.tsx';
import { DebitComponent } from './debit/debit.tsx';
import { useUser } from '../../hooks/user.hook.ts';
import { EnvProvider } from '../../context/env.provider.tsx';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { paths } from '../../environment/path.ts';
import DrawerComponent from '../utils/DrawerComponent.tsx';
import { AccountGap } from './account_gab.tsx';



interface ExpenseAppProps {}

const ExpenseApp: React.FC<ExpenseAppProps> = () => {
  const navigate = useNavigate();
  const [components, setComponents] = useState<{
    list: JSX.Element | null;
    info: JSX.Element | null;
  }>({
    list: null,
    info: null,
  });
  const [currentEnv, setCurrentEnv] = useState<string>('debit');
  const { user, error,fetchUser } = useUser();

  useEffect(() => {
    const init = async () => {
      await fetchUser();
      setupStartEnvComponent();
    };
    init();
  }, []);

  useEffect(() => {
    if(error) navigate(`/${paths.SignIn}`);
  }, [error]);

  const setupStartEnvComponent = () => {
    setUpListOfDebits();
  };

  const setUpListOfDebits = () => {
    setComponents({ list: <DebitComponent />, info: <AccountGap /> });
  };

  const setUpListOfExpectations = () => {
    setComponents({
      list: <Expectation/>, info:  <AccountGap/>,
    });
  };

  const setUpListOfExpenses = async () => {
    setComponents({ list: <Expense />, info: null });
  };


  // const handleClickEnv = (event: React.MouseEvent<HTMLElement>) => {
  //   matchingComponentAliases((event.target as HTMLButtonElement).id);
  // };

  const handleEnvChange = (env: string) => {
    setCurrentEnv(env);
    matchingComponentAliases(env);
  };

  const matchingComponentAliases = (alias: string) => {
    setCurrentEnv(alias);
    if (alias === 'debit') setUpListOfDebits();
    if (alias === 'expense') setUpListOfExpenses();
    if (alias === 'expectation') setUpListOfExpectations();
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(/images/terrasse.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: '#fff',
      }}
    >
      <HomeBar />
      <EnvProvider currentEnv={currentEnv}>
      <DrawerComponent/>
        <Container maxWidth="lg">
          {/* Top Navigation */}
          <Grid container spacing={3} sx={{ py: 4 }}>
            <Grid item xs={12} md={8}>
              <ButtonEnvironment onClick={(e) => handleEnvChange(e.currentTarget.id)} />
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<InventoryIcon />}
                  component={Link}
                  to="/expenseApp/pack"
                >
                  Pack
                </Button>
              </Stack>
            </Grid> */}
          </Grid>

          {/* Main Content */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} sx={{ mt: 5 }}>
              {user ? <Savings user={user} /> : ''}
            </Grid>
            <Grid item xs={12} md={8}>
              {components.list}
            </Grid>
          </Grid>


          {/* Additional Information */}
          <Box mt={4}>{components.info}</Box>
        </Container>
      </EnvProvider>
    </Box>
  );
};

export default ExpenseApp;


import { User } from '../model/user.model.ts';
import { apiClient,apiAuthClient } from '../environment/env.ts';


/**
 * Adds a user to the system.
 * @param user The user object to add.
 * @param registration_code An optional registration code.
 * @returns The response object from the server.
 */
export const addUser = async (user: User,registration_code?: string): Promise<void> => {
  const url = registration_code? `/access/register?registration_code=${registration_code}`: `/access/register`;

  try {
    const response = await apiClient.post(url, user);
    localStorage.setItem('token', response.data);
  } catch (error) {
    // Handle the error
    console.error('Error adding user:', error);
    throw error;
  }
};

/**
 * Verifies a user's credentials.
 * @param user The user object containing login credentials.
 * @returns The response object from the server.
 */
export const verifyUser = async (user: User): Promise<void> => {
  try {
    const response = await apiClient.post(`/access/login`, user);
    localStorage.setItem('token', response.data);
  } catch (error) {
    // Handle the error
    console.error('Error verifying user:', error);
    throw error;
  }
};

/**
 * Fetches the currently authenticated user.
 * @returns The authenticated user object.
 */
export const getCurrentUser = async (): Promise<User> => {
  try {
    const response = await apiAuthClient.get('/user');
    return response.data; 
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

interface NumberInputAdornmentsProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const NumberInputAdornments: React.FC<NumberInputAdornmentsProps> = ({ onChange }): React.JSX.Element => {
  const [value, setValue] = useState<number | ''>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    setValue(inputValue !== '' ? parseFloat(inputValue) : '');
    if (onChange) onChange(event);
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div>
        <FormControl fullWidth sx={{ m: 3 }} variant="filled">
          <InputLabel htmlFor="filled-adornment-amount">Cost</InputLabel>
          <FilledInput
            id="filled-adornment-amount"
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            type="number"
            value={value}
            onChange={handleInputChange}
            sx={{
              width: {
                xs: '100%', // 100% width on extra small screens
                sm: '70%',  // 50% width on small screens
                md: '80%',  // 40% width on medium screens
              }
            }}
          />
        </FormControl>
      </div>
    </Box>
  );
};

export default NumberInputAdornments;

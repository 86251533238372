import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

interface GroupOrientationProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const GroupOrientation = ({ onClick }: GroupOrientationProps): React.JSX.Element => {
  const options = [
    { id: 'debit', label: 'Prélèvement' },
    { id: 'expectation', label: 'Prévision' },
    { id: 'expense', label: 'Dépense' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        '& > *': { m: 1 },
      }}
    >
      <ButtonGroup variant="contained">
        {options.map((option) => (
          <Button
            key={option.id}
            id={option.id}
            onClick={onClick}
            aria-label={`Changer vers ${option.label}`}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default GroupOrientation;

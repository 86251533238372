import React, { useEffect, useState, useCallback } from 'react';
import PackCard from './packCard.tsx';
import Typography from '@mui/material/Typography';
import { Pack } from '../../../model/pack.model.ts';
import { usePacks } from '../../../hooks/pack.hook.ts';
import HomeBar from '../../utils/homebar.tsx';
import DrawerComponent from '../../utils/DrawerComponent.tsx';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { EnvProvider } from '../../../context/env.provider.tsx';
import IconButton from '@mui/material/IconButton';

interface PackChooseProps {}

const PackChoose: React.FC<PackChooseProps> = () => {
  const { packs } = usePacks();
  const [list, setList] = useState<React.JSX.Element[] | null>(null);
  const { initializePack, deletePack, updatePack,getSummary } = usePacks();

  const getImgSrc = (image: any): string => {
    return image
      ? createImg(image.imageData)
      : 'https://www.kidyhome.com/cdn/shop/products/ours-peluche-gant-auburn-90cm-456.webp?v=1680293619&width=1445';
  };

  const createImg = (imgData: string): string => {
    const byteCharacters = atob(imgData);
    const byteArrays: number[] = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  const handleUpdatePack = (id: number) => {
    let budget = parseFloat(
      (document.getElementById('filled-adornment-amount') as HTMLInputElement).value
    ) || 0;
    const packTitle = (document.getElementById('fullWidth') as HTMLInputElement).value;

    const packObject: Pack = {
      budget,
      expectations: null,
      debits: null,
      title: packTitle || null,
    };
    updatePack(id, packObject);
  };

  const createListOfPacks = useCallback(
    (packObjects: Pack[]): React.JSX.Element[] => {
      return packObjects.map((pack) => (
          <PackCard
            id={pack.id!}
            key={pack.id}
            title={pack.title!}
            budget={pack.budget}
            src={getImgSrc(pack.image)}
            handleClick={handleClick}
            deletePack={deletePack}
            updatePack={handleUpdatePack}
          />
        
      ));
    },
    [deletePack, handleUpdatePack]
  );

  const handleClick = (id: number) => {
    initializePack(id);
  };

  const getListOfPack = useCallback(() => {
    setList(createListOfPacks(packs));
  }, [packs]);

  useEffect(() => {
    getListOfPack();
  }, [packs, getListOfPack]);

  return (
    <>
      <HomeBar />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '100%', alignItems : 'center' }}>
        <DrawerComponent />
        <IconButton onClick={() => getSummary()}>
          <SummarizeIcon sx={{color:'black'}}/>
        </IconButton>
      </Box>
      <EnvProvider currentEnv={''}>
        <Typography
          variant="h5" // Titre plus petit
          component="div"
          gutterBottom
          sx={{
            textAlign: 'center', // Centrage du texte
            color: 'primary.main', // Couleur principale du thème
            fontWeight: 'bold', // Titre en gras
            borderBottom: '2px solid', // Ligne sous le titre
            borderColor: 'primary.main', // Même couleur que le texte
            paddingBottom: '8px',
            marginBottom: '24px', // Ajout d'une marge plus importante sous le titre
          }}
        >
          Choix du pack pour ce mois
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2 }}> {/* Ajout d'une marge supérieure */}
          {list}
        </Grid>
      </EnvProvider>
    </>
  );
};

export default PackChoose;

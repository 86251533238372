import { useContext } from "react";
import { ExpensesContext } from "../context/expense.context.tsx";

export const useExpenses = () => {
  const context = useContext(ExpensesContext);
  if (!context) {
    throw new Error('useExpenses must be used within a ExpensesProvider');
  }
  return context;
};

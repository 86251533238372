import React from 'react';
import { videos } from '../../scripts/videos.js';
import Carousel from 'react-bootstrap/Carousel';
import Video from './video.jsx';
import HomeBar from '../utils/homebar.tsx';
import Typography from '@mui/material/Typography';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class PianoGallery extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         authorizedHeader: null,
         itemsPerSlide: this.getItemsPerSlide(), // Initial number of items per slide
      };
   }

   componentDidMount() {
      // Add resize event listener
      window.addEventListener('resize', this.updateItemsPerSlide);
   }

   componentWillUnmount() {
      // Clean up the resize event listener
      window.removeEventListener('resize', this.updateItemsPerSlide);
   }

   updateItemsPerSlide = () => {
      this.setState({ itemsPerSlide: this.getItemsPerSlide() });
   };

   getItemsPerSlide() {
      const width = window.innerWidth;
      if (width < 600) return 1; // Mobile - 1 video per slide
      if (width < 960) return 2; // Tablet - 2 videos per slide
      return 4; // Desktop - 4 videos per slide
   }

   displayVideos(category, nbToDisplay) {
      const filtered = videos.filter(video => video.category === category);
      return this.createCarousel(filtered, nbToDisplay);
   }

   createCarousel(videos, nbToDisplay) {
      const n = videos.length;
      let start = 0;
      const res = [];
      while (start + nbToDisplay < n) {
         res.push(this.createCarouselItem(videos, start, start + nbToDisplay));
         start += nbToDisplay;
      }
      if (start + nbToDisplay >= n) res.push(this.createCarouselItem(videos, start, start + nbToDisplay));
      return res;
   }

   createCarouselItem(videos, start, end) {
      return (
         <Carousel.Item key={start}>
            <div style={this.getCarouselItemWrapperStyles()}>
               {videos.slice(start, end).map(video => <Video key={video.src} src={video.src} title={video.title} />)}
            </div>
         </Carousel.Item>
      );
   }

   render() {
      const { itemsPerSlide } = this.state; // Dynamic number of items per slide
      const categories = [...new Set(videos.map(video => video.category))];

      return (
         <div style={this.getGalleryStyles()}>
            <HomeBar />
            <div style={this.getGalleryHeaderStyles()}>
               <Typography variant="h1" component="div" style={this.getGalleryTitleStyles()}>
                  Maestro Maishima
               </Typography>
            </div>

            {categories.map(category => (
               <div key={category} style={this.getCategorySectionStyles()}>
                  <Typography variant="h2" style={this.getCategoryTitleStyles()}>
                     {category}
                  </Typography>
                  <Carousel
                     indicators={true}
                     controls={true}
                     prevIcon={<span className="carousel-control-prev-icon" />}
                     nextIcon={<span className="carousel-control-next-icon" />}
                     style={this.getCustomCarouselStyles()}
                     interval={null}
                     pause="hover"
                     wrap={false}
                  >
                     {this.displayVideos(category, itemsPerSlide)}
                  </Carousel>
               </div>
            ))}
         </div>
      );
   }

   // Styles en ligne
   getGalleryStyles = () => ({
      backgroundColor: 'black',
      padding: '20px',
   });

   getGalleryHeaderStyles = () => ({
      textAlign: 'center',
      marginBottom: '40px',
   });

   getGalleryTitleStyles = () => ({
      fontSize: '5rem',
      backgroundImage: 'linear-gradient(120deg, blue, violet)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
   });

   getCategorySectionStyles = () => ({
      marginBottom: '60px',
   });

   getCategoryTitleStyles = () => ({
      color: 'white',
      fontSize: '2rem',
      marginBottom: '20px',
      padding: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderRadius: '10px',
   });

   getCarouselItemWrapperStyles = () => ({
      display: 'flex',
      flexWrap: 'nowrap',
      gap: '1rem',
      justifyContent: 'center',
      overflowX: 'auto',
   });

   getCustomCarouselStyles = () => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
   });
}

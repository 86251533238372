import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { usePacks } from '../../../hooks/pack.hook.ts';
import { Pack } from '../../../model/pack.model.ts';
import HomeBar from '../../utils/homebar.tsx';
import DrawerComponent from '../../utils/DrawerComponent.tsx';
import BlocTextFiled from '../../utils/BlocTextFiled.tsx';
import ButtonSendPack from '../../utils/buttonSendPack.tsx';
import PriceInput from '../../utils/priceInput.tsx';
import FullWidthTextField from '../../utils/LargeTextField.tsx';

const PackComponent: React.FC = () => {
  const [alertMsg, setAlertMsg] = useState<React.JSX.Element | null>(null);
  const { createPack, error } = usePacks();

  const handleAddPack = async () => {
    const budget = parseFloat((document.getElementById('filled-adornment-amount') as HTMLInputElement).value) || 0;
    const expectations = (document.getElementById('expectations') as HTMLInputElement).value;
    const debits = (document.getElementById('debits') as HTMLInputElement).value;
    const packTitle = (document.getElementById('fullWidth') as HTMLInputElement).value;

    const packObject: Pack = {
      budget,
      expectations,
      debits,
      title: packTitle,
    };

    const imageFile = (document.getElementById('imageInput') as HTMLInputElement).files?.[0];
    createPack(packObject, imageFile);

    setAlertMsg(
      !error
        ? <Alert severity="success">Pack successfully created.</Alert>
        : <Alert severity="error">An error occurred. Please try again.</Alert>
    );
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', color: 'text.primary' }}>
      <HomeBar />
      <DrawerComponent />
      {alertMsg && <Box mb={2}>{alertMsg}</Box>}
      <Grid container spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} sx={{ mt: 4, p:2 }}>
          <Typography variant="h5" component="div">
            Choix du budget :
          </Typography>
          <PriceInput />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom variant="h5" component="div">
            Initialisation des Prélèvements :
          </Typography>
          <BlocTextFiled id="debits" label="prélèvements" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography gutterBottom variant="h5" component="div">
            Initialisation des Prévisions :
          </Typography>
          <BlocTextFiled id="expectations" label="prévisions" />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="div">
            Titre de la Formule :
          </Typography>
          <FullWidthTextField />
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 2 }}
          >
            Add Image (Optional)
            <input type="file" hidden id="imageInput" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ButtonSendPack onClick={handleAddPack} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackComponent;

import React from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const VideoInfo = ({ title }) => {
  const theme = useTheme();

  // Points d'arrêt pour réactivité
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getTypographySize = () => {
    if (isSmallScreen) {
      return { titleSize: '1rem', bodySize: '0.75rem' }; // Petits écrans
    } else if (isMediumScreen) {
      return { titleSize: '1.25rem', bodySize: '0.875rem' }; // Écrans moyens
    } else {
      return { titleSize: '1.5rem', bodySize: '1rem' }; // Écrans larges
    }
  };

  const { titleSize, bodySize } = getTypographySize();

  return (
    <CardContent
      sx={{
        maxWidth: isSmallScreen ? 250 : isMediumScreen ? 300 : 350, // Largeur en fonction de l'écran
        maxHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '8px',
      }}
    >
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        color="darkcyan"
        sx={{
          fontSize: titleSize, // Taille dynamique pour le titre
          fontWeight: 'bold',
        }}
      >
        Details
      </Typography>
      <Typography
        variant="body2"
        color="whitesmoke"
        sx={{
          fontSize: bodySize, // Taille dynamique pour le texte
        }}
      >
        Title: {title}
      </Typography>
      {/* 
      <Typography variant="body2" color="whitesmoke" sx={{ fontSize: bodySize }}>
        views (remplacer par une icone) : 0
      </Typography> 
      */}
    </CardContent>
  );
};

export default VideoInfo;

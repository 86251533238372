import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface FullWidthTextFieldProps {
  onChange? : (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id?: string;
}

function FullWidthTextField({ label = "description", id = "fullWidth",onChange }: FullWidthTextFieldProps): React.JSX.Element {
  return (
    <Box
      sx={{
        width: 150,
        maxWidth: '100%',
      }}
    >
      <TextField fullWidth label={label} id={id} onChange={onChange!} />
    </Box>
  );
}

export default FullWidthTextField;

import React, { useState } from 'react';
import { Container } from 'reactstrap';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useDebits } from '../../../hooks/debit.hook.ts';
import List from '../../utils/list.tsx';
import LargeTextField from '../../utils/LargeTextField.tsx';
import ButtonDelete from '../../utils/buttonDelete.tsx';
import ButtonAdd from '../../utils/buttonAdd.tsx';
import MoneyOnIcon from '../../utils/moneyOnIcon.tsx';
import MoneyOffIcon from '../../utils/moneyOffIcon.tsx';
import { Debit } from '../../../model/debit.model.ts';
import PriceInput from '../../utils/priceInput.tsx';
import Alert from '@mui/material/Alert';
import { useUser } from '../../../hooks/user.hook.ts';
import Typography from '@mui/material/Typography';
import ModalInput from '../../utils/ModalInput.tsx';
import FullWidthTextField from '../../utils/LargeTextField.tsx';

interface DebitTableComponent {
  id: string;
  description: string;
  cost: number;
  debitStatus: boolean;
  action: 'create' | 'delete';
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any) => React.ReactNode;
}





export const DebitComponent: React.FC = () => {
  const { debits, error, createDebit, updateDebit, deleteDebit } = useDebits();
  const {fetchUser} = useUser();

  const [updateTitle,setUpdateTitle] = useState<string>('');
  const [updateCoast,setUpdateCoast] = useState<number>(0);

  function formatDebitStatus(debit: DebitTableComponent, updateDebit : Function): React.JSX.Element {
  
    const updateDebitStatusField_test = () => {
      updateDebitStatusField(+debit.id!, debit.debitStatus,updateDebit );
    };
  
    if (debit.action === 'create') {
      return <MonetizationOnIcon />;
    }
    return debit.debitStatus ? (
      <MoneyOffIcon id={debit.id} updateDebit={updateDebitStatusField_test} />
    ) : (
      <MoneyOnIcon id={debit.id} updateDebit={updateDebitStatusField_test} />
    );
  }
  
  function initColumns(createDebit: Function, deleteDebit: Function,updateDebit: Function, fetchUser: Function): Column[] {
    return [
      { id: 'description', label: 'Description', minWidth: 170 },
      { id: 'cost', label: 'Cost', minWidth: 100 },
      {
        id: 'debitStatus',
        label: 'Status of Debit',
        minWidth: 50,
        format: (debit: DebitTableComponent) => formatDebitStatus(debit, updateDebit),
      },
      {
        id: 'action',
        label: 'Action',
        minWidth: 50,
        format: (debit: DebitTableComponent) =>
          debit.action === 'create' ? (
            <ButtonAdd onClick={async () => {
              const toCreate = getCreateDebitFields();
              await createDebit(toCreate);
              await fetchUser();
            }} />
          ) : (
            <>
              <ButtonDelete id={debit.id} onClick={async () => {
                await deleteDebit(debit.id);
                await fetchUser();
              }} />
  
              <ModalInput
                buttonTitle=''
                isSignIn={false}
                descriptionHeader="Modifier le budget (Champ modifiable)"
                buttonColor="primary"
                modalBody={
                  <>
                    Nouveau titre : <FullWidthTextField onChange={(event) => setUpdateTitle(event.target.value)}/>
                    <br />
                    Nouveau budget : <PriceInput onChange={(event) => setUpdateCoast(+event.target.value)}/>
                  </>
                }
                callBackOnSubmit={()=> updateDebitField(+debit.id)}
              />
          </>
          ),
      },
    ];
  }
  
  function getCreateDebitFields() : Debit | undefined {
    const costElement = document.getElementById('filled-adornment-amount') as HTMLInputElement | null;
    const descriptionElement = document.getElementById('fullWidth') as HTMLInputElement | null;
    // Validation des éléments
    if (!costElement || !descriptionElement) {
      console.error('Impossible de trouver les champs de saisie requis.');
      return;
    }
    let cost: number = parseFloat(costElement.value);
    cost = isNaN(cost) ? 0.0 : cost; // Définit à 0.0 si la valeur est vide ou invalide
    const debitObject: Debit = {
      description: descriptionElement.value.trim(),
      cost: cost,
      isDebited: false,
    };
    return debitObject;
  }
  
  function updateDebitStatusField(id :number,status:boolean, updateDebit: Function){
    const debitObject: Debit = {
      isDebited: !status,
      description: null,
      cost: null
    };
    updateDebit(id, debitObject);
  }
  
  function updateDebitField(id :number){
    if(updateTitle !== '' && updateCoast !== 0){
      const debitObject: Debit = {
        description: updateTitle,
        cost: updateCoast,
        isDebited: null
      };
      console.log(debitObject);
      updateDebit(id, debitObject);
    }
  }
  
  function initRows(debits: Debit[]): DebitTableComponent[] {
    const createRow: any = {
      id: 'createDebit',
      description: <LargeTextField />,
      cost: <PriceInput/>,  
      debitStatus: false,
      action: 'create',
    };
    
    const rows = debits.map((debit) => ({
      id: debit.id!,
      description: debit.description,
      cost: debit.cost,
      debitStatus: debit.isDebited,
      action: 'delete',
    }));
    return [createRow,...rows];
  }

  
  const columns = initColumns(createDebit, deleteDebit, updateDebit,fetchUser);
  const rows = initRows(debits);

  const totalOfDebit = debits.reduce((prev,elm) => prev+elm.cost!,0);
  const sumOfDebited = debits.filter(elm => elm.isDebited).reduce((prev,elm) => prev+elm.cost!,0);


  const total = [<Typography variant="body1">Total Debited : {sumOfDebited.toFixed(1)} / {totalOfDebit.toFixed(1)} €</Typography>,
    <Typography variant="body1">Total Remaining : {(totalOfDebit - sumOfDebited).toFixed(1)} / {totalOfDebit.toFixed(1)} €</Typography>,
  ];

  


  return (
    <div className="app">
      <Container fluid>
        {error ? <Alert severity="error"> {error} </Alert> : ''}
        <List rows={rows} columns={columns} title='All your debits 💸' total={total}  />
      </Container>
    </div>
  );
};

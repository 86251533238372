// export const baseUrl = `http://localhost:8765`;
import axios from 'axios';

const domain_url : string = (process.env.REACT_APP_PRODUCTION_MODE) ? 
                            ((process.env.REACT_APP_PRODUCTION_MODE === 'DEV') ? 
                                    `http://localhost:8765/api` : `https://jean-emmanuel-diap.com/api`) 
                             : `http://localhost:8765`;

export const baseUrl : string = process.env.REACT_APP_BASE_URL || domain_url;

export const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  export const apiAuthClient = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : localStorage.getItem('token')
    },
  });


  apiAuthClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token && config.headers['Authorization'] !== token) {
      config.headers['Authorization'] = token;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
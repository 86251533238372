import React, { createContext, useState, useCallback, useEffect } from 'react';
import { DebitService } from '../service/debit.service.ts';
import { Debit } from '../model/debit.model.ts';

type DebitsContextType = {
  debits: Debit[];
  loading: boolean;
  error: string | null;
  createDebit: (debit: Debit) => Promise<void>;
  updateDebit: (id: number, debit: Debit) => Promise<void>;
  deleteDebit: (id: number) => Promise<void>;
};

export const DebitsContext = createContext<DebitsContextType | null>(null);

export const DebitsProvider = ({ children }: { children: React.ReactNode }) => {
  const [debits, setDebits] = useState<Debit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const debitService = new DebitService();

  const fetchDebits = useCallback(async () => {
    setLoading(true);
    try {
      const data = await debitService.getDebits();
      setDebits(data);
    } catch {
      setError('Erreur lors de la récupération des débits.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDebits();
  }, [fetchDebits]);

  const createDebit = async (debit: Debit) => {
    setLoading(true);
    try {
      const newDebit = await debitService.createDebit(debit);
      setDebits((prev) => [...prev, newDebit]);
    } catch {
      setError('Erreur lors de la création du débit.');
    } finally {
      setLoading(false);
    }
  };

  const updateDebit = async (id: number, debit: Debit) => {
    setLoading(true);
    try {
      const updatedDebit = await debitService.updateDebit(id, debit);
      setDebits((prev) => prev.map((d) => (d.id === id ? updatedDebit : d)));
    } catch {
      setError('Erreur lors de la mise à jour du débit.');
    } finally {
      setLoading(false);
    }
  };

  const deleteDebit = async (id: number) => {
    setLoading(true);
    try {
      await debitService.deleteDebit(id);
      setDebits((prev) => prev.filter((d) => d.id !== id));
    } catch {
      setError('Erreur lors de la suppression du débit.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DebitsContext.Provider value={{ debits, loading, error, createDebit, updateDebit, deleteDebit }}>
      {children}
    </DebitsContext.Provider>
  );
};

import React, { useState } from 'react';
import { Container } from 'reactstrap';
import LargeTextField from '../../utils/LargeTextField.tsx';
import Slider from '../../utils/Slider.tsx';
import { useExpectations } from '../../../hooks/expectation.hook.ts';  // Assurez-vous que ce chemin est correct
import { Expectation } from '../../../model/expectation.model.ts';
import ButtonAdd from '../../utils/buttonAdd.tsx';
import ButtonDelete from '../../utils/buttonDelete.tsx';
import PriceInput from '../../utils/priceInput.tsx';
import List from '../../utils/list.tsx';
import { useUser } from '../../../hooks/user.hook.ts';
import { Box, Typography } from '@mui/material';
import ModalInput from '../../utils/ModalInput.tsx';
import FullWidthTextField from '../../utils/LargeTextField.tsx';

interface ExpectationsProps {
};

interface ExpectationTableComponent{
  id: string;
  name: string;
  previsionExpected: number;
  previsionSpending: number;
  action : string;
}


const Expectations: React.FC = ({} : ExpectationsProps) => {
  const { expectations, loading, error, createExpectation, deleteExpectation, updateExpectation } = useExpectations();
  const {fetchUser} = useUser();
  
  const [updateTitle,setUpdateTitle] = useState<string>('');
  const [updateCoast,setUpdateCoast] = useState<number>(0);
  
  const [newExpectation, setNewExpectation] = useState({
    name: '',
    previsionExpected: 0.0,
  });

  const handleAddExpectation = () => {
    
    const expectationObject : Expectation = {
      name: newExpectation.name,
      previsionExpected: newExpectation.previsionExpected,
      previsionSpending: 0.0,
      expenses : [],
    };
    createExpectation(expectationObject);
  };

  const handleDeleteExpectation = (id: number) => {
    deleteExpectation(id);
  };

  function updateExpectationField(id :number){
      if(updateTitle !== '' && updateCoast !== 0){
        const expectationObject: Expectation = {
          name: updateTitle,
          previsionExpected: updateCoast,
          previsionSpending: null,
          expenses: null
        };
        // console.log(expectationObject);
        updateExpectation(id,expectationObject);
      }
    }

  const initColumns = () => {
    return [
      { id: 'name', label: 'Name', minWidth: 170},
      { id: 'previsionSpending', label: 'Amount', minWidth: 50},
      { id: 'previsionExpected', label: 'Expected', minWidth: 100},
      { id: 'action', label: 'Action', minWidth: 50, format: (val: any) =>
        (val.action.desc === 'ajouter') ? <ButtonAdd onClick={ async () =>{
          await handleAddExpectation();
          await fetchUser();
        }
        } /> 
        
        : 
        <>
        <ButtonDelete id={val.id} onClick={async () =>{
              await handleDeleteExpectation(val.id);
              await fetchUser();
            }} 
         />
         <ModalInput
                buttonTitle=''
                isSignIn={false}
                descriptionHeader="Modifier le budget (Champ modifiable)"
                buttonColor="primary"
                modalBody={
                  <>
                    Nouvelle description : <FullWidthTextField onChange={(event) => setUpdateTitle(event.target.value)}/>
                    <br />
                    Nouveau plafond attendu : <PriceInput onChange={(event) => setUpdateCoast(+event.target.value)}/>
                  </>
                }
                callBackOnSubmit={()=> updateExpectationField(+val.id)}
              />
            </>
      },
    ];
  };

  const initRows = () => {
    const rows = expectations.map((expectation) => createData(
      expectation.id!,
      expectation.name,
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
        }}>
          {(expectation.previsionSpending! > expectation.previsionExpected) ? <Typography variant='body2' style={{paddingRight:16, color:'red', fontWeight:'bold'}}>{expectation.previsionExpected - expectation.previsionSpending!}€</Typography> : ''}
          <Slider maxValue={expectation.previsionExpected} defaultValue={expectation.previsionSpending!}  />
      </Box>
      ,
      expectation.previsionExpected,
      { desc: 'delete', id: expectation.id }
    ));
    return [createExpectationRow(), ...rows];
  };

  const createData = (id: number, name: string, previsionSpending: React.JSX.Element, previsionExpected: number, action: any) => {
    return { id, name, previsionSpending, previsionExpected, action };
  };

  const createExpectationRow = () => {
    const id = 'createexpectation';
    const name = <LargeTextField onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewExpectation({ ...newExpectation, name: e.target.value })} />;
    const previsionSpending = '';
    const previsionExpected = <PriceInput onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewExpectation({ ...newExpectation, previsionExpected: parseFloat(e.target.value) })} />;
    const action = { desc: 'ajouter', id };
    return { id, name, previsionSpending, previsionExpected, action };
  };

  const columns = initColumns();
  const rows = initRows();

  const totalOfPrevisionExpected= expectations.reduce((prev,elm) => prev+elm.previsionExpected!,0);
  const sumOfPrevisionSpending = expectations.reduce((prev,elm) => prev+elm.previsionSpending!,0);

  const total = [<Typography variant="body1">Total Spent : {sumOfPrevisionSpending.toFixed(1)} / {totalOfPrevisionExpected.toFixed(1)} €</Typography>,
    <Typography variant="body1">Total Remaining : {(totalOfPrevisionExpected - sumOfPrevisionSpending).toFixed(1)} / {totalOfPrevisionExpected.toFixed(1)} €</Typography>,
  ];

  return (
    <div className="app">
      <Container fluid>
        { error ? <p>{error}</p> : ''}
        {loading ? <p>Loading...</p> : <List rows={rows} columns={columns} title='All your expectations 🔏' total={total}  />}
      </Container>
    </div>
  );
};

export default Expectations;

import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ShopIcon from '@mui/icons-material/Shop';
import { paths } from '../../environment/path.ts';
import { useNavigate } from 'react-router-dom';

interface DrawerProps {
}

const DrawerComponent: React.FC<DrawerProps> = ({}) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const menuItems = [
    { name: 'Home', icon: <InboxIcon />, redirect : `/${paths.ExpenseApp}` },
    { name: 'Pack Configuration', icon: <ShopIcon />, redirect : `/${paths.PackCreate}` },
    { name: 'Pack Choice', icon: <PriceChangeIcon />, redirect : `/${paths.PackChoose}`},
  ];

  return (
    <>
      {/* Icône pour ouvrir le drawer */}
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{
          color: '#fff',
          position: 'absolute',
          top: 60,  // Juste en dessous de la barre de navigation
          left: 16,
          zIndex: 10,  // Pour s'assurer que l'icône est au-dessus des autres éléments
        }}
      >
        <AutoAwesomeMosaicIcon fontSize="large" sx={{ color: 'black' }} />
      </IconButton>

      {/* Drawer latéral */}
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            height: '100%',
            backgroundColor: '#6A4F37',  // Couleur marron bois
            color: '#ffffff',  // Texte en blanc
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={() => navigate(item.redirect)}>
                  <ListItemIcon sx={{ color: '#ffffff' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} sx={{ color: '#ffffff' }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)' }} />
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerComponent;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container } from 'reactstrap';
import List from '../../utils/list.tsx';
import PriceInput from '../../utils/priceInput.tsx'
import ButtonDelete from '../../utils/buttonDelete.tsx';
import ButtonAdd from '../../utils/buttonAdd.tsx';
import LargeTextField from '../../utils/LargeTextField.tsx';
import Select from '../../utils/Select.tsx';
import { useExpenses } from '../../../hooks/expense.hook.ts';
import { useExpectations } from '../../../hooks/expectation.hook.ts';
import { Expense } from '../../../model/expense.model.tsx';
import ModalInput from '../../utils/ModalInput.tsx';
import FullWidthTextField from '../../utils/LargeTextField.tsx';



const ExpenseComponent: React.FC = () => {
  const { expenses, loading, error,fetchExpenses,updateExpense,getExpense } = useExpenses();
  const { expectations, createExpectationExpense, deleteExpense } = useExpectations();

  const selectRef = useRef<{ getSelectedValue: () => string | null }>(null);
  const [displayList, setDisplayList] = useState<JSX.Element | null>(null);

  useEffect(() => {
    updateList();
  }, [expenses,expectations]);

  const updateTitleRef = useRef('');
  const updateCoastRef = useRef(0);

  const handleTitleChange = (value: string) => {
    updateTitleRef.current = value;
  };

  const handleCoastChange = (value: number) => {
    updateCoastRef.current = value;
  };

  async function updateExpenseField(id: number) {
    const updateTitle = updateTitleRef.current;
    const updateCoast = updateCoastRef.current;
    if (updateTitle !== '' && updateCoast !== 0) {
      const expenseObject: Expense = {
        description: updateTitle,
        price: updateCoast,
        category: null,
      };
      await updateExpense(id, expenseObject);
    }
  }
  

  const updateList = useCallback(() => {
    const columns = initColumns();
    const rows = initRows(expenses);
    setDisplayList(<List rows={rows} columns={columns} title='All your expenses 🧗🏼‍♂️' />);
  }, [expenses]);

  const initColumns = () => [
    {
      id: 'description',
      label: 'Description',
      minWidth: 170,
    },
    {
      id: 'cost',
      label: 'Cost',
      minWidth: 100,
      format: (row: any) => row.cost,
    },
    {
      id: 'category',
      label: 'Category',
      minWidth: 50,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      minWidth: 50,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 50,
      format: (val: any) =>
        val.action.desc === 'ajouter' ? (
          <ButtonAdd onClick={ async () => {
            await addExpense();
            fetchExpenses();
          }} />
        ) : (
          <>
          <ButtonDelete id={val.action.id} onClick={ async (event) =>{
            await deleteExpenseHandler(event);
            fetchExpenses();
          }
            } />
      
            <ModalInput
            buttonTitle=''
            isSignIn={false}
            descriptionHeader="Modifier le budget (Champ modifiable)"
            buttonColor="primary"
            modalBody={
              <>
                Nouvelle description : <FullWidthTextField onChange={(event) => handleTitleChange(event.target.value)}/>
                <br />
                Nouveau plafond attendu : <PriceInput onChange={(event) => handleCoastChange(+event.target.value)}/>
              </>
            }
            callBackOnSubmit={()=> updateExpenseField(+val.id)}
          />
        </>
        ),
    },
  ];

  const initRows = (data: Expense[]) => {
    const createSection = createExpenseData();
    const rows = data.map((expense) =>
      createData(expense.id!, expense.description, expense.price, expense.category!, expense.createdAt!, {
        desc: 'delete',
        id: `${expense.id}`,
      })
    );
    return [createSection, ...rows];
  };

  const createData = (
    id: number | string,
    description: string,
    cost: number,
    category: string,
    createdAt:string,
    action: { desc: string; id: string }
  ) => ({ id, description, cost, category,createdAt, action });

  const createExpenseData = () => {
    const id = 'createExpense&create';
    const description = <LargeTextField />;
    const cost = <PriceInput />;
    const category = <Select key={'select'} ref={selectRef} data={expectations} />;
    const date = '';
    const action = { desc: 'ajouter', id };
    return { id, description, cost, category,date, action };
  };

  const addExpense = async () => {
    const costInput = document.getElementById('filled-adornment-amount') as HTMLInputElement;
    const descriptionInput = document.getElementById('fullWidth') as HTMLInputElement;
    const cost = costInput?.value ? parseFloat(costInput.value) : 0.0;
    const description = descriptionInput?.value;

    const selectedValue = selectRef.current?.getSelectedValue();

    if (!selectedValue || !description) {
      alert('Veuillez remplir tous les champs et sélectionner une catégorie.');
      return;
    }
    const newExpense : Expense = {
      description,
      price: cost,
      category: 'test', // Remplacez par la vraie catégorie si nécessaire
    };
    try {
      await createExpectationExpense(+selectedValue, newExpense);
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la dépense', error);
    }
  };

  const deleteExpenseHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const id = +event.currentTarget.id;
    const expense = await getExpense(id);
    const category = (expense) ? expense.category : null;
    
    if (!id || !category) return;
    try {
      await deleteExpense(category,id);
    } catch (error) {
      console.error('Erreur lors de la suppression de la dépense', error);
    }
  };

  return (
    <div className='app'>
      <Container fluid>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          displayList
        )}
      </Container>
    </div>
  );
};

export default ExpenseComponent;

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useTheme } from '@mui/material';

interface ModalInputProps {
  isSignIn:boolean,
  buttonTitle:string,
  buttonColor: string;
  descriptionHeader: string;
  modalBody: React.ReactNode;
  callBackOnSubmit: () => void;
  callBackOnClick?: () => void;
}

function ModalInput({
  isSignIn=false,
  buttonTitle='',
  buttonColor,
  descriptionHeader,
  modalBody,
  callBackOnSubmit,
  callBackOnClick,
}: ModalInputProps): React.JSX.Element {
  const [show, setShow] = useState(false);

  const handleClose = (): void => {
    setShow(false);
  };

  const handleSubmit = (): void => {
    callBackOnSubmit();
    setShow(false);
  };

  const handleShow = (): void => {
    if (callBackOnClick) callBackOnClick();
    setShow(true);
  };

  const theme = useTheme();
  const paletteColor = theme.palette[buttonColor];

  return (
    <>
    {
      (isSignIn)
       ? 
        <Button variant={buttonColor} onClick={handleShow}>
          {buttonTitle}
        </Button>
      :  
      <IconButton
        onClick={handleShow}
        sx={{
          color: paletteColor.main,
          border: `1px solid ${paletteColor.main}`,
          padding: 1,
          '&:hover': {
            backgroundColor: paletteColor.light,
            color: paletteColor.contrastText,
          },
        }}
      >
      <SettingsIcon />
    </IconButton>
    }
     

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{descriptionHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="button" variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalInput;

import { apiAuthClient } from '../environment/env.ts';
import { Expectation } from '../model/expectation.model.ts';
import { Expense } from '../model/expense.model.ts';

export class ExpectationService {
    
  async getExpectations(): Promise<Expectation[]> {
    const response = await apiAuthClient.get<Expectation[]>('/expectations');
    return response.data;
  }

  async getExpectation(id: number): Promise<Expectation> {
    const response = await apiAuthClient.get<Expectation>(`/expectation/${id}`);
    return response.data;
  }

  async createExpectation(expectation: Expectation): Promise<Expectation> {
    const response = await apiAuthClient.post<Expectation>('/expectation', expectation);
    return response.data;
  }

  async updateExpectation(id:number,expectation: Expectation) : Promise<Expectation>{
    const response = await apiAuthClient.put<Expectation>(`/expectation/${id}`, expectation);
    return response.data;
  }

  async addExpense(expectationId: number, expense: Expense): Promise<Expense> {
    const response = await apiAuthClient.post<Expense>(`/expectation/${expectationId}/expense`, expense);
    return response.data;
  }

  async deleteExpectation(id: number): Promise<void> {
    await apiAuthClient.delete(`/expectation/${id}`);
  }

  async deleteExpense(expectationCategory: string, expenseId: number): Promise<void> {
    await apiAuthClient.delete(`/expectation/${expectationCategory}/expense/${expenseId}`);
  }
}

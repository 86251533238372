import React from 'react';
import { DebitsProvider } from './debit.context.tsx';
import { ExpectationsProvider } from './expectation.context.tsx';
import { ExpensesProvider } from './expense.context.tsx';

export const EnvProvider: React.FC<{ children: React.ReactNode; currentEnv: string }> = ({ children, currentEnv }) => {
  let ProviderComponent: React.FC<{ children: React.ReactNode }> | null = null;

  switch (currentEnv) {
    case 'debit':
      ProviderComponent = DebitsProvider;
      break;
    case 'expectation':
      ProviderComponent = ExpectationsProvider;
      break;
    case 'expense':
      ProviderComponent = ({ children }) => (
        <ExpectationsProvider>
          <ExpensesProvider>{children}</ExpensesProvider>
        </ExpectationsProvider>
      );
      break;
    default:
      ProviderComponent = null;
  }

  if (!ProviderComponent) {
    return <>{children}</>; // En cas de contexte inconnu, on retourne juste les enfants sans contexte.
  }

  return <ProviderComponent>{children}</ProviderComponent>;
};

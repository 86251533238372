import React from 'react';
import VideoInfo from './videoinfo.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Video = ({ src, title }) => {
  const theme = useTheme();

  // Points d'arrêt pour réactivité
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getCardDimensions = () => {
    if (isSmallScreen) {
      return { width: 200, height: 300 }; // Dimensions pour les petits écrans
    } else if (isMediumScreen) {
      return { width: 250, height: 350 }; // Dimensions pour les écrans moyens
    } else {
      return { width: 300, height: 480 }; // Dimensions par défaut
    }
  };

  const { width, height } = getCardDimensions();

  return (
    <Card
      className="video"
      sx={{
        maxWidth: width,
        maxHeight: height,
        backgroundColor: 'black',
        borderRadius: '15px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        transition: '0.3s',
        '&:hover': {
          boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.3)',
          transform: 'scale(1.05)',
        },
      }}
    >
      <video
        width={width}
        height={height * 0.4} // Hauteur vidéo proportionnelle
        controls
        style={{
          objectFit: 'cover',
          borderRadius: '15px 15px 0 0',
          display: 'block',
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
      <CardContent
        sx={{
          padding: '8px',
          backgroundColor: '#1e1e1e',
          borderRadius: '0 0 15px 15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: height * 0.3, // Hauteur du bloc info proportionnelle
          width: '100%',
        }}
      >
        <VideoInfo
          title={title}
          sx={{
            fontSize: isSmallScreen ? '0.75rem' : '0.875rem', // Ajuste la taille du texte
            margin: 0,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default Video;

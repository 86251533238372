import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

interface IconLabelButtonsProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
}

const IconLabelButtons = ({ onClick, id }: IconLabelButtonsProps): React.JSX.Element => {
  const theme = useTheme();
  
  return (
    <IconButton
      onClick={onClick}
      id={id}
      sx={{
        color: theme.palette.error.main, // applique la couleur rouge
        border: `1px solid ${theme.palette.error.main}`, // ajout d'une bordure rouge
        '&:hover': {
          backgroundColor: theme.palette.error.light, // fond léger au survol
          color: theme.palette.error.contrastText, // couleur du texte (icône) au survol
        },
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export default IconLabelButtons;

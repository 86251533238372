

import React,{ useState, useEffect, useCallback, createContext } from 'react';
import { ExpenseService } from '../service/expense.service.ts';
import { Expense } from '../model/expense.model.ts';

type ExpensesContextType = {
    expenses : Expense[],
    loading : boolean,
    error : string | null,
    createExpense : (expense : Expense) => Promise<void>,
    updateExpense : (id:number, expense : Expense) => Promise<void>,
    deleteExpense : (id : number) => Promise<void>,
    fetchExpenses : () => Promise<void>,
    getExpense : (id : number) => Promise<Expense|null>
};

export const ExpensesContext = createContext<ExpensesContextType|null>(null);

export const ExpensesProvider = ({ children }: { children: React.ReactNode }) => {
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const expenseService = new ExpenseService();

  const fetchExpenses = useCallback(async () => {
    setLoading(true);
    try {
      const data = await expenseService.getExpenses();
      setExpenses(data);
    } catch (err) {
      setError('Erreur lors de la récupération des dépenses.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const getExpense = useCallback(async (id: number) => {
    setLoading(true);
    try {
      const expense = await expenseService.getExpense(id);
      return expense;
    } catch (err) {
      setError('Erreur lors de la récupération de la dépense.');
      console.error(err);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  const createExpense = useCallback(async (expense: Expense) => {
    setLoading(true);
    try {
      const newExpense = await expenseService.createExpense(expense);
      setExpenses((prevExpenses) => [...prevExpenses, newExpense]);
    } catch (err) {
      setError('Erreur lors de la création de la dépense.');
    } finally {
      setLoading(false);
    }
  }, []);
  
  const updateExpense = useCallback(async (id: number, expense: Expense) => {
    setLoading(true);
    try {
      const updatedExpense = await expenseService.updateExpense(id, expense);
      setExpenses((prevExpenses) =>
        prevExpenses.map((e) => (e.id === id ? updatedExpense : e))
      );
    } catch (err) {
      setError('Erreur lors de la mise à jour de la dépense.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);
  
  const deleteExpense = useCallback(async (id: number) => {
    setLoading(true);
    try {
      await expenseService.deleteExpense(id);
      setExpenses((prevExpenses) => prevExpenses.filter((e) => e.id !== id));
    } catch (err) {
      setError('Erreur lors de la suppression de la dépense.');
    } finally {
      setLoading(false);
    }
  }, []);
  

  return (
  <ExpensesContext.Provider value={{expenses, loading, error, createExpense, updateExpense, deleteExpense,fetchExpenses,getExpense}}>
    {children}
  </ExpensesContext.Provider> 
  );
};

import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box } from '@mui/material';
import { User } from '../../../model/user.model';

interface SavingsProps {
  user: User;
}

const Savings = ({ user }: SavingsProps): React.JSX.Element => {
  // Détermine la couleur des économies en fonction de la valeur
  const savingsColor = user.savings! < 0 ? '#e63946' : '#2a9d8f'; // Rouge si négatif, vert si positif

  return (
    <Card
      sx={{
        maxWidth: 350,
        width: '90%',
        margin: 'auto',
        background: 'rgba(29, 53, 87, 0.9)', // Couleur de fond avec transparence
        color: '#f1faee',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
        borderRadius: 4,
        overflow: 'hidden',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 8px 25px rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <CardActionArea>
        <Box
          sx={{
            position: 'relative',
            height: 200,
            overflow: 'hidden',
          }}
        >
          <CardMedia
            component="img"
            image="/images/terrasse.jpg"
            alt="terrasse"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: 'brightness(1.1)', // Légère augmentation de la luminosité
              transition: 'filter 0.3s ease',
              '&:hover': { filter: 'brightness(1.2)' }, // Un peu plus lumineux au survol
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: 2,
              background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" component="div" sx={{ color: savingsColor, fontWeight: 'bold' }}>
              {user.savings!.toFixed(2)}
            </Typography>
            <Typography variant="h6" component="div">
              Épargne
            </Typography>
          </Box>
        </Box>
        <CardContent>
          <Typography variant="body1" color="#f1faee" textAlign="center" sx={{ fontStyle: 'italic' }}>
            {`"${user.login}, imagine-toi, tu es incroyable !"`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Savings;

import React, { useCallback } from 'react';
import { Card, CardMedia, Typography, CardActionArea, Box } from '@mui/material';
import ButtonDelete from '../../utils/buttonDelete.tsx';
import ModalInput from '../../utils/ModalInput.tsx';
import PriceInput from '../../utils/priceInput.tsx';
import FullWidthTextField from '../../utils/LargeTextField.tsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface PackCardProps {
  id: number;
  src: string;
  title: string;
  budget: number;
  handleClick: (id: number) => void;
  deletePack: (id: number) => void;
  updatePack: (id: number) => void;
}

const PackCard: React.FC<PackCardProps> = ({ id, src, title, budget, handleClick, deletePack, updatePack }) => {
  const [open, setOpen] = React.useState(false);
  const handleCardClick = useCallback(() => handleClick(id), [handleClick, id]);
  const handleDelete = useCallback(() => deletePack(id), [deletePack, id]);
  const handleUpdate = useCallback(() => updatePack(id), [updatePack, id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box 
      sx={{ 
        maxWidth: 300, 
        width: '100%', 
        margin: 'auto', 
        boxShadow: 3, 
        borderRadius: 2, 
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
        '&:hover': { transform: 'scale(1.05)' },
      }}
    >
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardActionArea onClick={handleClickOpen}>
          <CardMedia
            component="img"
            height="200"
            image={src}
            alt={title}
          />
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Budget : {budget} €
            </Typography>
          </Box>
        </CardActionArea>
        <Box 
          sx={{
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: 2,
          }}
        >
          <ButtonDelete id={String(id)} onClick={handleDelete} />
          <ModalInput
            isSignIn={false}
            buttonTitle=''
            descriptionHeader="Modifier le budget"
            buttonColor="primary"
            modalBody={
              <>
                Nouveau budget : <PriceInput />
                <br />
                Titre (optionnel) : <FullWidthTextField />
              </>
            }
            callBackOnSubmit={handleUpdate}
          />
        </Box>
      </Card>

      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Pack Configuration"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you confirm the request, knows that previous pack configuration will be reset. 
            You can click on the summary icon to get a recap by mail.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={()=>{
            handleCardClick();
            setOpen(false);
          }} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PackCard;

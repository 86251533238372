import { useState, useEffect, useCallback } from 'react';
import { PackService } from '../service/pack.service.ts';
import { Pack } from '../model/pack.model.ts';

export const usePacks = () => {
  const [packs, setPacks] = useState<Pack[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const packService = new PackService();

  const fetchPacks = useCallback(async () => {
    setLoading(true);
    try {
      const data = await packService.getPacks();
      setPacks(data);
    } catch (err) {
      setError('Erreur lors de la récupération des packs.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPacks();
  }, [fetchPacks]);

  const getSummary = async() => {
    try {
      await packService.getSummary();
    } catch (error) {
      setError('Erreur lors de la demande de résumé');
    } finally {
      setLoading(false);
    }
  }

  const createPack = async (pack: Pack,imageFile?:File) => {
    setLoading(true);
    try {
      const newPack = await packService.createPack(pack,imageFile);
      setPacks((prevPacks) => [...prevPacks, newPack]);
    } catch (err) {
      setError('Erreur lors de la création du pack.');
    } finally {
      setLoading(false);
    }
  };

  const updatePack = async (id: number, pack: Pack) => {
    setLoading(true);
    try {
      const updatedPack = await packService.updatePack(id, pack);
      setPacks((prevPacks) =>
        prevPacks.map((p) => (p.id === id ? updatedPack : p))
      );
    } catch (err) {
      setError('Erreur lors de la mise à jour du pack.');
    } finally {
      setLoading(false);
    }
  };

  const deletePack = async (id: number) => {
    setLoading(true);
    try {
      await packService.deletePack(id);
      setPacks((prevPacks) => prevPacks.filter((p) => p.id !== id));
    } catch (err) {
      setError('Erreur lors de la suppression du pack.');
    } finally {
      setLoading(false);
    }
  };

  const initializePack = async (id: number) => {
    setLoading(true);
    try {
      await packService.initializePack(id);
      fetchPacks(); // Actualise la liste après l'initialisation
    } catch (err) {
      setError('Erreur lors de l\'initialisation du pack.');
    } finally {
      setLoading(false);
    }
  };

  return { packs, loading, error, fetchPacks,createPack, updatePack, deletePack,initializePack,getSummary};
};

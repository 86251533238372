import React,{ useState, useEffect, useCallback, createContext } from 'react';
import { ExpectationService } from '../service/expectation.service.ts';
import { Expectation } from '../model/expectation.model.ts';
import { Expense } from '../model/expense.model.ts';

type ExpectationsContextType = {
    expectations : Expectation[],
    loading : boolean,
    error : string | null,
    createExpectation : (expectation : Expectation) => Promise<void>,
    deleteExpectation : (id : number) => Promise<void>,
    createExpectationExpense : (expectationId:number,expense:Expense) => Promise<void>,
    updateExpectation : (id : number,expectation : Expectation) => Promise<void>,
    deleteExpense :  (category: string, id: number) => void,
}

export const ExpectationsContext = createContext<ExpectationsContextType|null>(null);

export const ExpectationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [expectations, setExpectations] = useState<Expectation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const expectationService = new ExpectationService();

  const fetchExpectations = useCallback(async () => {
    setLoading(true);
    try {
      const data = await expectationService.getExpectations();
      setExpectations(data);
    } catch (err) {
      setError('Erreur lors de la récupération des attentes.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExpectations();
  }, [fetchExpectations]);

  const createExpectation = async (expectation: Expectation) => {
    setLoading(true);
    try {
      const newExpectation = await expectationService.createExpectation(expectation);
      setExpectations((prevExpectations) => [...prevExpectations, newExpectation]);
    } catch (err) {
      setError('Erreur lors de la création de l\'expectation : '+err);
    } finally {
      setLoading(false);
    }
  };

  const createExpectationExpense = async (expectationId:number,expense:Expense) => {
    try {
      const newExpense = await expectationService.addExpense(expectationId,expense);
    } catch (error) {
      setError('Erreur lors de la création de l\'expense : ' + error);
    }finally {
      setLoading(false);
    }
  };

  const updateExpectation = async (id: number, expectation: Expectation) => {
    setLoading(true);
    try {
      const updatedExpectation = await expectationService.updateExpectation(id, expectation);
      setExpectations((prevExpectations) =>
        prevExpectations.map((e) => (e.id === id ? updatedExpectation : e))
      );
    } catch (err) {
      setError('Erreur lors de la mise à jour de l\'aexpectation.');
    } finally {
      setLoading(false);
    }
  };

  const deleteExpectation = async (id: number) => {
    setLoading(true);
    try {
      await expectationService.deleteExpectation(id);
      setExpectations((prevExpectations) =>
        prevExpectations.filter((e) => e.id !== id)
      );
    } catch (err) {
      setError('Erreur lors de la suppression de l\'attente.');
    } finally {
      setLoading(false);
    }
  };

  const deleteExpense = async (category: string, id: number) => {
    setLoading(true);
    try {
      await expectationService.deleteExpense(category, id);
      setExpectations((prevExpectations) =>
        prevExpectations.map((expectation) =>
          expectation.name === category
            ? {
                ...expectation,
                expenses: expectation.expenses!.filter((expense) => expense.id !== id),
              }
            : expectation
        )
      );
    } catch (err) {
      setError('Erreur lors de la suppression de l\'expense : ' + err);
    } finally {
      setLoading(false);
    }
  };
  

  return (<ExpectationsContext.Provider value={{ expectations, loading, error, createExpectation, deleteExpectation,createExpectationExpense,updateExpectation,deleteExpense }}>
            {children}
        </ExpectationsContext.Provider>) ;
};

import React from 'react';
import { Box, Card, CardContent, Checkbox, Typography } from '@mui/material';
import PriceInput from '../utils/priceInput.tsx';
import GapModalInput from '../utils/GapModalInput.tsx';
import { GapProvider } from '../../context/gap.context.tsx';

export const AccountGap: React.FC = () => {
  return (
    <Card
      sx={{
        background: 'linear-gradient(145deg, #3e2723, #6a4f37)', // Dégradé marron
        color: '#ffffff',
        padding: 3,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: 3,
      }}
    >
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Bank Account Gap
        </Typography>
        <Box
        sx={{
          display: 'flex', // Utiliser flexbox pour l'alignement horizontal
          alignItems: 'center', // Aligner verticalement les éléments
          gap: 1, // Espacement entre les éléments (optionnel)
        }}
      >
          <Typography variant="body1">
            Here you can verify whether your expenses you entered are up to date with your bank account
          </Typography>
          <GapProvider>
            <GapModalInput 
              buttonColor={'dark'} 
              buttonTitle={'Take a look'} 
              descriptionHeader={''} 
            />
          </GapProvider>
      </Box>
        
        
      </CardContent>
    </Card>
  );
}


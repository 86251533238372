import React from 'react';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

interface MoneyOffIconProps {
  id: string | number;
  updateDebit: (id: string | number) => void;
}

function MoneyOffICON({ id, updateDebit }: MoneyOffIconProps): React.JSX.Element {
  const handleClick = (): void => {
    updateDebit(id);
  };

  return (
    <div>
      <MoneyOffIcon id={id.toString()} onClick={handleClick} />
    </div>
  );
}

export default MoneyOffICON;

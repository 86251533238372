import { apiAuthClient } from '../environment/env.ts';
import { Expense } from '../model/expense.model.ts';

export class ExpenseService {
  async getExpenses(): Promise<Expense[]> {
    const response = await apiAuthClient.get<Expense[]>('/expenses');
    return response.data;
  }

  async getExpense(id: number): Promise<Expense> {
    const response = await apiAuthClient.get<Expense>(`/expense/${id}`);
    return response.data;
  }

  async createExpense(expense: Expense): Promise<Expense> {
    const response = await apiAuthClient.post<Expense>('/expense', expense);
    return response.data;
  }

  async updateExpense(id: number, expense: Expense): Promise<Expense> {
    const response = await apiAuthClient.put<Expense>(`/expense/${id}`, expense);
    return response.data;
  }

  async deleteExpense(id: number): Promise<void> {
    await apiAuthClient.delete(`/expense/${id}`);
  }
}
